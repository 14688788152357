<template>
  <div
    :id="popupId"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myLargeModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h2>User Response</h2>
          <button
            ref="popupCloseButton"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body" v-if="!loadingUserPicklDetails">
            <div class="green-border-box " style="padding:1%;" >
                <h2>&nbsp;&nbsp;Basic Details: <small style="float:right;margin-right:15px;font-weight:bold;">Status: {{nonGeoPicklDetails.user_pickl.status}}</small></h2>
                <div class="col-sm-6">
                  <table class="table user-pickl-table">
                    <tr>
                      <td>
                        <h3>Picklr Name</h3>
                      </td>
                      <td>
                        <h3> <strong>{{nonGeoPicklDetails.user_pickl.user.name}}</strong></h3>
                      </td>
                    </tr>
                    <tr v-if="!brand">
                      <td>
                        <h3>Mobile Number</h3>
                      </td>
                      <td>
                        <h3><strong>{{nonGeoPicklDetails.user_pickl.user.mobile_number | phone}}</strong></h3>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h3>Device</h3>
                      </td>
                      <td>
                        <h3><strong>{{nonGeoPicklDetails.user_pickl.device}}</strong></h3>
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="col-sm-6">
                  <table class="table user-pickl-table">
                    <tr>
                      <td>
                        <h3>App Installed</h3>
                      </td>
                      <td>
                        <h3><strong>{{nonGeoPicklDetails.user_pickl.app_installed == 1? "Yes" : "NO"}}</strong></h3>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h3>Loyalty Number</h3>
                      </td>
                      <td>
                        <h3><strong>{{nonGeoPicklDetails.user_pickl.loyalty_number}}</strong></h3>
                      </td>
                    </tr>
                  </table>
                </div>
            </div>
            <div class="green-border-box " style="padding:1%;margin-top:10px; padding-left:20px;" >
                <h2>Media Insight Details</h2>
                <h3>Insight Name: <strong>{{nonGeoPicklDetails.user_pickl.pickl.media.survey.title }}</strong></h3>
                <h3>Insight Description: <strong>{{nonGeoPicklDetails.user_pickl.pickl.media.survey.description }}</strong></h3>
                <br/>
                <h2>Questions</h2>
                <div class="row single-quesion-box" v-for="question in nonGeoPicklDetails.user_pickl.pickl.media.survey.survey_questions" :key="question.id" style="padding-left:20px;">
                    <div class="col-md-6">
                    <strong>Question: </strong><span>{{question.question}}</span>
                    </div>
                    <div class="col-md-6">
                    <strong>Question Type: </strong
                    ><span> {{question.question_type}}</span>
                    </div>
                    <div class="col-sm-12">
                    <h4 style="margin-left: 2%; margin-top: 2%" v-if="question.question_type!=='TEXT'">Answers</h4>
                    <ul v-if="question.question_type!=='TEXT'" >
                        <li v-for="answer in question.survey_question_answers" :key="answer.id">{{answer.answer_option}} <span style="color:green;" v-if="inMediaSurveyAnswers(answer.id)">✓</span></li>
                    </ul>
                    <h4 v-else style="margin-left:20px; margin-bottom:25px;">Answer: <strong v-html="getMediaTextQuestionAnswer(question.id)"></strong></h4>
                    </div>
                </div>
            </div>
            <div class="green-border-box " style="padding:1%;margin-top:10px; padding-left:20px;" >
                <h2>Pickl Insight Details</h2>
                <h3>Insight Name: <strong>{{nonGeoPicklDetails.user_pickl.pickl.survey.title }}</strong></h3>
                <h3>Insight Description: <strong>{{nonGeoPicklDetails.user_pickl.pickl.survey.description }}</strong></h3>
                <br/>
                <h2>Questions</h2>
                <div class="row single-quesion-box" v-for="question in nonGeoPicklDetails.user_pickl.pickl.survey.survey_questions" :key="question.id" style="padding-left:20px;">
                    <div class="col-md-6">
                    <strong>Question: </strong><span>{{question.question}}</span>
                    </div>
                    <div class="col-md-6">
                    <strong>Question Type: </strong
                    ><span> {{question.question_type}}</span>
                    </div>
                    <div class="col-sm-12">
                        <h4 style="margin-left: 2%; margin-top: 2%" v-if="question.question_type!=='TEXT'">Answers</h4>
                        <ul v-if="question.question_type!=='TEXT'" >
                            <li v-for="answer in question.survey_question_answers" :key="answer.id">{{answer.answer_option}} <span style="color:green;" v-if="inPicklSurveyAnswers(answer.id)">✓</span></li>
                        </ul>
                        <h4 v-else style="margin-left:20px; margin-bottom:25px;">Answer: <strong v-html="getTextQuestionAnswer(question.id)"></strong></h4>
                    </div>
                </div>
            </div>
            <div class="green-border-box " style="padding:2%;margin-top:10px;" v-if="nonGeoPicklDetails.user_pickl.status=='APPROVED' || nonGeoPicklDetails.user_pickl.status=='REJECTED'">
              <br/>
              <div class="col-sm-6">
                <h4>Shopper Notes:</h4>
                <div v-if="!brand">
                  <div>
                    {{nonGeoPicklDetails.user_pickl.note || 'None'}}
                  </div>
                </div>
                <div v-else>
                  <div v-if="nonGeoPicklDetails.user_pickl.show_notes_to_brand == 1">
                    {{nonGeoPicklDetails.user_pickl.note || 'None'}}
                  </div>
                  <div v-else>
                    None
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <h4>Admin Notes:</h4>
                {{nonGeoPicklDetails.user_pickl.admin_note || 'None'}}
                <br><br>
                <strong v-if="nonGeoPicklDetails.user_pickl.admin_rating!==null">{{ brand ? 'Admin' : 'You' }} rated to Picklr </strong><img v-for="n in nonGeoPicklDetails.user_pickl.admin_rating" :key="n" src="/img/Alerts/picklerating_icon.png" />
              </div>
            </div>
            <div class="green-border-box " style="padding:2%;margin-top:10px;" v-if="nonGeoPicklDetails.user_pickl.status=='COMPLETED' && isSadmin">
              <div class="text-center">
                <div class="col-sm-4" style="text-align:left;">
                  <h4>Shopper Notes: </h4>
                  <p v-html="nonGeoPicklDetails.user_pickl.note" style="text-align:left;font-size:16px;"></p>
                </div>
                <div class="col-sm-8">
                  <h4>Rate the Picklr</h4>
                  <div class="rate-picklr" @mouseout="hoverRating = 0">
                    <span v-for="i in 5" :key="i" @mouseover="hoverRating = i" @click="rating = i"><img :src="getImagePath(i)" /></span>
                  </div>
                  <textarea maxlength="120" placeholder="Notes" v-model="adminNote"></textarea>
                  <div class="col-md-6">
                    <input type="checkbox" v-model="ShowNotesToBrand" checked id="show-to-brand"/><label for="show-to-brand">Show Notes To Brand</label>
                  </div>
                  <div class="col-md-6">
                      <input type="checkbox" v-model="needReview" id="brand-attention-needed"/><label for="brand-attention-needed">Attention Needed</label>
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div v-if="!brand" class="col-xs-12 nopadd">
                  <div class="col-xs-6 text-right">
                    <button class="btn-lg-grey" @click="rejectPickl">REJECT</button>
                  </div>
                  <div class="col-xs-6 text-left">
                    <button class="btn-lg-green" @click="approvePickl">APPROVE</button>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <div class="modal-body" v-else>
            <h2 style="text-align:center;">Loading Data...</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['popupId', 'nonGeoPicklDetails', 'loadingUserPicklDetails', 'brand'],
  data: function () {
    return {
      hoverRating: 0,
      rating: 0,
      adminNote: '',
      needReview: '',
      ShowNotesToBrand: 1
    }
  },
  computed: {
    isSadmin () {
      return (
        this.$store.state.userData &&
        (this.$store.state.userData.role === 'super-admin')
      )
    }
  },
  methods: {
    getImagePath: function (i) {
      if (this.hoverRating > 0) {
        if (i <= this.hoverRating) return '/img/Alerts/starActive_icon.png'
        else return '/img/Alerts/star_icon.png'
      } else if (i <= this.rating) return '/img/Alerts/starActive_icon.png'
      else return '/img/Alerts/star_icon.png'
    },

    inPicklSurveyAnswers (answerId) {
      let status = false
      this.nonGeoPicklDetails.pickl_survey_answers.forEach(x => {
        if (x.answer_id === answerId) {
          status = true
          return true
        }
      })
      return status
    },
    inMediaSurveyAnswers (answerId) {
      let status = false
      this.nonGeoPicklDetails.media_survey_answers.forEach(x => {
        if (x.answer_id === answerId) {
          status = true
          return true
        }
      })
      return status
    },
    getTextQuestionAnswer (questionId) {
      let answer = ''
      this.nonGeoPicklDetails.pickl_survey_answers.forEach(x => {
        if (x.question_id === questionId) {
          answer = x.text_answer
        }
      })
      return answer
    },
    getMediaTextQuestionAnswer (questionId) {
      let answer = ''
      this.nonGeoPicklDetails.media_survey_answers.forEach(x => {
        if (x.question_id === questionId) {
          answer = x.text_answer
        }
      })
      return answer
    },
    rejectPickl () {
      if (this.adminNote === '') {
        this.$notify({ text: 'Please enter reason of rejection.', type: 'error' })
        return false
      } else if (this.adminNote.length > 120) {
        this.$notify({ text: 'Notes should not be more than 120 characters.', type: 'error' })
        return false
      } else {
        // this.needReview = (this.needReview)? 'Yes': 'No'
        let data = { admin_rating: this.rating, admin_note: this.adminNote, need_review: (this.needReview) ? 'Yes' : 'No', show_notes_to_brand: this.ShowNotesToBrand }
        this.$http
          .patch('user_pickls/non-geo/' + this.nonGeoPicklDetails.user_pickl.id + '/REJECTED', data)
          .then((response) => {
            this.resetAdminValues()
            this.$notify({ type: 'success', text: 'Pickl has been rejected successfully!' })
            this.$emit('statusUpdated')
            this.$refs.popupCloseButton.click()
          }, response => {
            this.$notify({ type: 'error', text: 'Failed to save pickl status' })
          })
      }
    },
    resetAdminValues () {
      this.hoverRating = 0
      this.rating = 0
      this.adminNote = ''
      this.needReview = ''
      this.ShowNotesToBrand = 1
    },

    approvePickl () {
      if (this.rating === 0) {
        this.$notify({ text: 'Please select rating for this pickl.', type: 'error' })
        return false
      } else if (this.adminNote.length > 120) {
        this.$notify({ text: 'Notes should not be more than 120 characters.', type: 'error' })
        return false
      } else {
        // this.needReview = (this.needReview)? 'Yes': 'No'
        let data = { admin_rating: this.rating, admin_note: this.adminNote, need_review: (this.needReview) ? 'Yes' : 'No', show_notes_to_brand: this.ShowNotesToBrand }
        this.$http
          .patch('user_pickls/non-geo/' + this.nonGeoPicklDetails.user_pickl.id + '/APPROVED', data)
          .then((response) => {
            this.resetAdminValues()
            this.$notify({ type: 'success', text: 'Pickl has been approved successfully!' })
            this.$emit('statusUpdated')
            this.$refs.popupCloseButton.click()
          }, response => {
            this.$notify({ type: 'error', text: 'Failed to save pickl status' })
          })
      }
    }
  }
}
</script>
<style scoped>
p {
  color: #232323;
  font-size: 24px;
  text-align: center;
}
.rate-picklr {
  cursor: pointer;
  margin-bottom: 45px;
}

.rate-picklr span {
  display: inline-block;
}

.rate-picklr img {
  width: 33px;
  height: 31px;
  margin: 0 8px;
}
table.user-pickl-table>tr {
  border: solid 1px gainsboro;
}
table.user-pickl-table>tr>td {
  padding: 5px 5px;
}
</style>
