var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: _vm.popupId,
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "myLargeModalLabel",
        "aria-hidden": "true"
      }
    },
    [
      _c(
        "div",
        { staticClass: "modal-dialog modal-lg modal-dialog-centered" },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c("h2", [_vm._v("User Response")]),
              _c("button", {
                ref: "popupCloseButton",
                staticClass: "close",
                attrs: {
                  type: "button",
                  "data-dismiss": "modal",
                  "aria-label": "Close"
                }
              })
            ]),
            !_vm.loadingUserPicklDetails
              ? _c("div", { staticClass: "modal-body" }, [
                  _c(
                    "div",
                    {
                      staticClass: "green-border-box ",
                      staticStyle: { padding: "1%" }
                    },
                    [
                      _c("h2", [
                        _vm._v("  Basic Details: "),
                        _c(
                          "small",
                          {
                            staticStyle: {
                              float: "right",
                              "margin-right": "15px",
                              "font-weight": "bold"
                            }
                          },
                          [
                            _vm._v(
                              "Status: " +
                                _vm._s(_vm.nonGeoPicklDetails.user_pickl.status)
                            )
                          ]
                        )
                      ]),
                      _c("div", { staticClass: "col-sm-6" }, [
                        _c("table", { staticClass: "table user-pickl-table" }, [
                          _c("tr", [
                            _vm._m(0),
                            _c("td", [
                              _c("h3", [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.nonGeoPicklDetails.user_pickl.user
                                        .name
                                    )
                                  )
                                ])
                              ])
                            ])
                          ]),
                          !_vm.brand
                            ? _c("tr", [
                                _vm._m(1),
                                _c("td", [
                                  _c("h3", [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("phone")(
                                            _vm.nonGeoPicklDetails.user_pickl
                                              .user.mobile_number
                                          )
                                        )
                                      )
                                    ])
                                  ])
                                ])
                              ])
                            : _vm._e(),
                          _c("tr", [
                            _vm._m(2),
                            _c("td", [
                              _c("h3", [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.nonGeoPicklDetails.user_pickl.device
                                    )
                                  )
                                ])
                              ])
                            ])
                          ])
                        ])
                      ]),
                      _c("div", { staticClass: "col-sm-6" }, [
                        _c("table", { staticClass: "table user-pickl-table" }, [
                          _c("tr", [
                            _vm._m(3),
                            _c("td", [
                              _c("h3", [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.nonGeoPicklDetails.user_pickl
                                        .app_installed == 1
                                        ? "Yes"
                                        : "NO"
                                    )
                                  )
                                ])
                              ])
                            ])
                          ]),
                          _c("tr", [
                            _vm._m(4),
                            _c("td", [
                              _c("h3", [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.nonGeoPicklDetails.user_pickl
                                        .loyalty_number
                                    )
                                  )
                                ])
                              ])
                            ])
                          ])
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "green-border-box ",
                      staticStyle: {
                        padding: "1%",
                        "margin-top": "10px",
                        "padding-left": "20px"
                      }
                    },
                    [
                      _c("h2", [_vm._v("Media Insight Details")]),
                      _c("h3", [
                        _vm._v("Insight Name: "),
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm.nonGeoPicklDetails.user_pickl.pickl.media
                                .survey.title
                            )
                          )
                        ])
                      ]),
                      _c("h3", [
                        _vm._v("Insight Description: "),
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm.nonGeoPicklDetails.user_pickl.pickl.media
                                .survey.description
                            )
                          )
                        ])
                      ]),
                      _c("br"),
                      _c("h2", [_vm._v("Questions")]),
                      _vm._l(
                        _vm.nonGeoPicklDetails.user_pickl.pickl.media.survey
                          .survey_questions,
                        function(question) {
                          return _c(
                            "div",
                            {
                              key: question.id,
                              staticClass: "row single-quesion-box",
                              staticStyle: { "padding-left": "20px" }
                            },
                            [
                              _c("div", { staticClass: "col-md-6" }, [
                                _c("strong", [_vm._v("Question: ")]),
                                _c("span", [_vm._v(_vm._s(question.question))])
                              ]),
                              _c("div", { staticClass: "col-md-6" }, [
                                _c("strong", [_vm._v("Question Type: ")]),
                                _c("span", [
                                  _vm._v(" " + _vm._s(question.question_type))
                                ])
                              ]),
                              _c("div", { staticClass: "col-sm-12" }, [
                                question.question_type !== "TEXT"
                                  ? _c(
                                      "h4",
                                      {
                                        staticStyle: {
                                          "margin-left": "2%",
                                          "margin-top": "2%"
                                        }
                                      },
                                      [_vm._v("Answers")]
                                    )
                                  : _vm._e(),
                                question.question_type !== "TEXT"
                                  ? _c(
                                      "ul",
                                      _vm._l(
                                        question.survey_question_answers,
                                        function(answer) {
                                          return _c("li", { key: answer.id }, [
                                            _vm._v(
                                              _vm._s(answer.answer_option) + " "
                                            ),
                                            _vm.inMediaSurveyAnswers(answer.id)
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      color: "green"
                                                    }
                                                  },
                                                  [_vm._v("✓")]
                                                )
                                              : _vm._e()
                                          ])
                                        }
                                      ),
                                      0
                                    )
                                  : _c(
                                      "h4",
                                      {
                                        staticStyle: {
                                          "margin-left": "20px",
                                          "margin-bottom": "25px"
                                        }
                                      },
                                      [
                                        _vm._v("Answer: "),
                                        _c("strong", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.getMediaTextQuestionAnswer(
                                                question.id
                                              )
                                            )
                                          }
                                        })
                                      ]
                                    )
                              ])
                            ]
                          )
                        }
                      )
                    ],
                    2
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "green-border-box ",
                      staticStyle: {
                        padding: "1%",
                        "margin-top": "10px",
                        "padding-left": "20px"
                      }
                    },
                    [
                      _c("h2", [_vm._v("Pickl Insight Details")]),
                      _c("h3", [
                        _vm._v("Insight Name: "),
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm.nonGeoPicklDetails.user_pickl.pickl.survey
                                .title
                            )
                          )
                        ])
                      ]),
                      _c("h3", [
                        _vm._v("Insight Description: "),
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm.nonGeoPicklDetails.user_pickl.pickl.survey
                                .description
                            )
                          )
                        ])
                      ]),
                      _c("br"),
                      _c("h2", [_vm._v("Questions")]),
                      _vm._l(
                        _vm.nonGeoPicklDetails.user_pickl.pickl.survey
                          .survey_questions,
                        function(question) {
                          return _c(
                            "div",
                            {
                              key: question.id,
                              staticClass: "row single-quesion-box",
                              staticStyle: { "padding-left": "20px" }
                            },
                            [
                              _c("div", { staticClass: "col-md-6" }, [
                                _c("strong", [_vm._v("Question: ")]),
                                _c("span", [_vm._v(_vm._s(question.question))])
                              ]),
                              _c("div", { staticClass: "col-md-6" }, [
                                _c("strong", [_vm._v("Question Type: ")]),
                                _c("span", [
                                  _vm._v(" " + _vm._s(question.question_type))
                                ])
                              ]),
                              _c("div", { staticClass: "col-sm-12" }, [
                                question.question_type !== "TEXT"
                                  ? _c(
                                      "h4",
                                      {
                                        staticStyle: {
                                          "margin-left": "2%",
                                          "margin-top": "2%"
                                        }
                                      },
                                      [_vm._v("Answers")]
                                    )
                                  : _vm._e(),
                                question.question_type !== "TEXT"
                                  ? _c(
                                      "ul",
                                      _vm._l(
                                        question.survey_question_answers,
                                        function(answer) {
                                          return _c("li", { key: answer.id }, [
                                            _vm._v(
                                              _vm._s(answer.answer_option) + " "
                                            ),
                                            _vm.inPicklSurveyAnswers(answer.id)
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      color: "green"
                                                    }
                                                  },
                                                  [_vm._v("✓")]
                                                )
                                              : _vm._e()
                                          ])
                                        }
                                      ),
                                      0
                                    )
                                  : _c(
                                      "h4",
                                      {
                                        staticStyle: {
                                          "margin-left": "20px",
                                          "margin-bottom": "25px"
                                        }
                                      },
                                      [
                                        _vm._v("Answer: "),
                                        _c("strong", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.getTextQuestionAnswer(
                                                question.id
                                              )
                                            )
                                          }
                                        })
                                      ]
                                    )
                              ])
                            ]
                          )
                        }
                      )
                    ],
                    2
                  ),
                  _vm.nonGeoPicklDetails.user_pickl.status == "APPROVED" ||
                  _vm.nonGeoPicklDetails.user_pickl.status == "REJECTED"
                    ? _c(
                        "div",
                        {
                          staticClass: "green-border-box ",
                          staticStyle: { padding: "2%", "margin-top": "10px" }
                        },
                        [
                          _c("br"),
                          _c("div", { staticClass: "col-sm-6" }, [
                            _c("h4", [_vm._v("Shopper Notes:")]),
                            !_vm.brand
                              ? _c("div", [
                                  _c("div", [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.nonGeoPicklDetails.user_pickl
                                            .note || "None"
                                        ) +
                                        "\n                "
                                    )
                                  ])
                                ])
                              : _c("div", [
                                  _vm.nonGeoPicklDetails.user_pickl
                                    .show_notes_to_brand == 1
                                    ? _c("div", [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.nonGeoPicklDetails.user_pickl
                                                .note || "None"
                                            ) +
                                            "\n                "
                                        )
                                      ])
                                    : _c("div", [
                                        _vm._v(
                                          "\n                  None\n                "
                                        )
                                      ])
                                ])
                          ]),
                          _c(
                            "div",
                            { staticClass: "col-sm-6" },
                            [
                              _c("h4", [_vm._v("Admin Notes:")]),
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.nonGeoPicklDetails.user_pickl
                                      .admin_note || "None"
                                  ) +
                                  "\n              "
                              ),
                              _c("br"),
                              _c("br"),
                              _vm.nonGeoPicklDetails.user_pickl.admin_rating !==
                              null
                                ? _c("strong", [
                                    _vm._v(
                                      _vm._s(_vm.brand ? "Admin" : "You") +
                                        " rated to Picklr "
                                    )
                                  ])
                                : _vm._e(),
                              _vm._l(
                                _vm.nonGeoPicklDetails.user_pickl.admin_rating,
                                function(n) {
                                  return _c("img", {
                                    key: n,
                                    attrs: {
                                      src: "/img/Alerts/picklerating_icon.png"
                                    }
                                  })
                                }
                              )
                            ],
                            2
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.nonGeoPicklDetails.user_pickl.status == "COMPLETED" &&
                  _vm.isSadmin
                    ? _c(
                        "div",
                        {
                          staticClass: "green-border-box ",
                          staticStyle: { padding: "2%", "margin-top": "10px" }
                        },
                        [
                          _c("div", { staticClass: "text-center" }, [
                            _c(
                              "div",
                              {
                                staticClass: "col-sm-4",
                                staticStyle: { "text-align": "left" }
                              },
                              [
                                _c("h4", [_vm._v("Shopper Notes: ")]),
                                _c("p", {
                                  staticStyle: {
                                    "text-align": "left",
                                    "font-size": "16px"
                                  },
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.nonGeoPicklDetails.user_pickl.note
                                    )
                                  }
                                })
                              ]
                            ),
                            _c("div", { staticClass: "col-sm-8" }, [
                              _c("h4", [_vm._v("Rate the Picklr")]),
                              _c(
                                "div",
                                {
                                  staticClass: "rate-picklr",
                                  on: {
                                    mouseout: function($event) {
                                      _vm.hoverRating = 0
                                    }
                                  }
                                },
                                _vm._l(5, function(i) {
                                  return _c(
                                    "span",
                                    {
                                      key: i,
                                      on: {
                                        mouseover: function($event) {
                                          _vm.hoverRating = i
                                        },
                                        click: function($event) {
                                          _vm.rating = i
                                        }
                                      }
                                    },
                                    [
                                      _c("img", {
                                        attrs: { src: _vm.getImagePath(i) }
                                      })
                                    ]
                                  )
                                }),
                                0
                              ),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.adminNote,
                                    expression: "adminNote"
                                  }
                                ],
                                attrs: {
                                  maxlength: "120",
                                  placeholder: "Notes"
                                },
                                domProps: { value: _vm.adminNote },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.adminNote = $event.target.value
                                  }
                                }
                              }),
                              _c("div", { staticClass: "col-md-6" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.ShowNotesToBrand,
                                      expression: "ShowNotesToBrand"
                                    }
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    checked: "",
                                    id: "show-to-brand"
                                  },
                                  domProps: {
                                    checked: Array.isArray(_vm.ShowNotesToBrand)
                                      ? _vm._i(_vm.ShowNotesToBrand, null) > -1
                                      : _vm.ShowNotesToBrand
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$a = _vm.ShowNotesToBrand,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.ShowNotesToBrand = $$a.concat([
                                              $$v
                                            ]))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.ShowNotesToBrand = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.ShowNotesToBrand = $$c
                                      }
                                    }
                                  }
                                }),
                                _c(
                                  "label",
                                  { attrs: { for: "show-to-brand" } },
                                  [_vm._v("Show Notes To Brand")]
                                )
                              ]),
                              _c("div", { staticClass: "col-md-6" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.needReview,
                                      expression: "needReview"
                                    }
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    id: "brand-attention-needed"
                                  },
                                  domProps: {
                                    checked: Array.isArray(_vm.needReview)
                                      ? _vm._i(_vm.needReview, null) > -1
                                      : _vm.needReview
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$a = _vm.needReview,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.needReview = $$a.concat([$$v]))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.needReview = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.needReview = $$c
                                      }
                                    }
                                  }
                                }),
                                _c(
                                  "label",
                                  { attrs: { for: "brand-attention-needed" } },
                                  [_vm._v("Attention Needed")]
                                )
                              ])
                            ])
                          ]),
                          _c("div", { staticClass: "col-sm-12" }, [
                            !_vm.brand
                              ? _c("div", { staticClass: "col-xs-12 nopadd" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-xs-6 text-right" },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn-lg-grey",
                                          on: { click: _vm.rejectPickl }
                                        },
                                        [_vm._v("REJECT")]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-xs-6 text-left" },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn-lg-green",
                                          on: { click: _vm.approvePickl }
                                        },
                                        [_vm._v("APPROVE")]
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e()
                          ])
                        ]
                      )
                    : _vm._e()
                ])
              : _c("div", { staticClass: "modal-body" }, [
                  _c("h2", { staticStyle: { "text-align": "center" } }, [
                    _vm._v("Loading Data...")
                  ])
                ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("h3", [_vm._v("Picklr Name")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("h3", [_vm._v("Mobile Number")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("h3", [_vm._v("Device")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("h3", [_vm._v("App Installed")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("h3", [_vm._v("Loyalty Number")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }